var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ReportEntityDetails__Component" },
    [
      _c("FieldInlineSelect", {
        attrs: {
          label: "סוג תאונה",
          readOnly: _vm.readOnly,
          filterable: false,
          clearable: false,
          options: [
            { Value: 0, Text: "אני הפוגע" },
            { Value: 1, Text: "אני הנפגע" },
            { Value: 2, Text: "נזק עצמי" },
            { Value: 3, Text: "נזק לרכוש" }
          ],
          required: "true"
        },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.AccidentType,
          callback: function($$v) {
            _vm.$set(_vm.model, "AccidentType", $$v)
          },
          expression: "model.AccidentType"
        }
      }),
      _c("FieldInlineText", {
        attrs: { label: "כתובת", readOnly: _vm.readOnly },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.Address,
          callback: function($$v) {
            _vm.$set(_vm.model, "Address", $$v)
          },
          expression: "model.Address"
        }
      }),
      _c("FieldInlineText", {
        attrs: { label: "לוחית רישוי", readOnly: _vm.readOnly },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.PlateNumber,
          callback: function($$v) {
            _vm.$set(_vm.model, "PlateNumber", $$v)
          },
          expression: "model.PlateNumber"
        }
      }),
      _c("FieldInlineDate", {
        attrs: { label: "תאריך האירוע", readOnly: _vm.readOnly },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.Date,
          callback: function($$v) {
            _vm.$set(_vm.model, "Date", $$v)
          },
          expression: "model.Date"
        }
      }),
      _c("FieldInlineTextArea", {
        attrs: { label: "תיאור המקרה", readOnly: _vm.readOnly },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.Description,
          callback: function($$v) {
            _vm.$set(_vm.model, "Description", $$v)
          },
          expression: "model.Description"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }