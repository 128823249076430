<template>
    <div class="ReportEntityDetails__Component">

     <FieldInlineSelect
        label="סוג תאונה"
        @onChange="onChange"
        :readOnly="readOnly"
        :filterable="false"
        :clearable="false"
        :options="[{Value: 0, Text: 'אני הפוגע'},
          {Value: 1, Text: 'אני הנפגע'},
          {Value: 2, Text: 'נזק עצמי'},
          {Value: 3, Text: 'נזק לרכוש'}]"
        required="true"
        v-model="model.AccidentType"
         />
      <FieldInlineText
        label="כתובת"
        @onChange="onChange"
        :readOnly="readOnly"
        v-model="model.Address"/>

      <FieldInlineText
        label="לוחית רישוי"
        @onChange="onChange"
        :readOnly="readOnly"
        v-model="model.PlateNumber"/>

        <FieldInlineDate
        label="תאריך האירוע"
        @onChange="onChange"
        :readOnly="readOnly"
        v-model="model.Date" />

      <FieldInlineTextArea
        label="תיאור המקרה"
        @onChange="onChange"
        :readOnly="readOnly"
        v-model="model.Description"/>

    </div>
</template>

<script>
import FieldInlineText from '@/components/Fields/FieldInlineText.vue';
import FieldInlineTextArea from '@/components/Fields/FieldInlineTextArea.vue';
import FieldInlineDate from '@/components/Fields/FieldInlineDate.vue';
import FieldInlineSelect from '@/components/Fields/FieldInlineSelect.vue';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'AccidentEntityDetails',
  components: {
    FieldInlineText,
    FieldInlineDate,
    FieldInlineTextArea,
    FieldInlineSelect,
  },
  props: ['readOnly'],
  data() {
    return {
      model: {
        AccidentType: null,
        Address: null,
        Date: null,
        Description: null,
        PlateNumber: null,
      },
    };
  },
  computed: {
    ...mapGetters('accident', [
      'form',
    ]),
    changesAmount() {
      return Object.keys(this.diff(this.model, this.form)).length;
    },
  },
  created() {
    this.model = { ...this.model, ...this.form };
  },
  methods: {
    diff(o1, o2) {
      const diff = Object.keys(o2).reduce((diff1, key) => {
        if (o1[key] && typeof o1[key].getMonth === 'function') {
          if (new Date(o1[key]).toString() === new Date(o2[key]).toString()) return diff1;
        }
        if (typeof o1[key] === 'number') {
          if (parseInt(o1[key], 10) === parseInt(o2[key], 10)) return diff1;
        }
        if (o1[key] && typeof o1[key] === 'object' && typeof o1[key].getMonth !== 'function') {
            if (o1[key]?.Id === o2[key]?.Id) return diff1;
        }
        if (o1[key] === o2[key]) return diff1;
        return {
          ...diff1,
          [key]: o2[key],
        };
      }, {});

      return diff;
    },
    onChange() {
      this.$emit('onFormChange', this.changesAmount);
    },
    cancel() {
      this.model = { ...this.model, ...this.form };
    },
    save() {
      this.$emit('onFormSave', {
        model: this.model,
        cancel: this.cancel,
        setRestorePoint: this.setRestorePoint,
      });
    },
    setRestorePoint() {
      this.setData(this.model);
    },
    refresh() {
      this.model = { ...this.model, ...this.form };
    },
    ...mapMutations('accident', [
      'setData',
    ]),
  },
};
</script>

<style>

</style>
