<template>
  <div class="Accident__Entity">
    <Entity
      ref="entity"
      entity-type="Accident"
      :entity-id="accidentId"
      :innerComponent="innerComponent"
      innerComponentTitle="פרטי תאונה"
      title="תאונה"
      back-button-text="רשימת תאונות"
      back-button-url="accidents"
      :widgets="widgets"
      :preview="preview"
      :read-only="readOnly"
      :entityReady="entityReady"
      :activities="[]"
      @onFormSave="onFormSave"
      @onFormDiscard="onFormDiscard"
    >
      <template #mandatory>
        <div class="Accident__Entity__Details__BaseDetails__Stage">
          <span class="ltr">לוחית רישוי:</span>
          <span
            class="
              Accident__Entity__Details__BaseDetails__Stage__Value
              value
              has-text-weight-bold
            "
            >{{ PlateNumber }}</span
          >
        </div>
        <div class="Accident__Entity__Details__BaseDetails__Stage">
          <span class="ltr">תאריך יצירה:</span>
          <span
            class="
              Accident__Entity__Details__BaseDetails__Stage__Value
              value
              has-text-weight-bold
            "
            >{{ CreateDate | date }}</span
          >
        </div>
        <div class="Accident__Entity__Details__BaseDetails__Stage">
          <span class="ltr">סטטוס מסמך:</span>
          <span
            class="
              Accident__Entity__Details__BaseDetails__Stage__Value
              value
              has-text-weight-bold
            "
            >{{ documentStatus }}</span
          >
        </div>
      </template>
    </Entity>
  </div>
</template>

<script>
import Entity from "@/components/Entity.vue";
import AccidentEntityDetails from "@/components/AccidentEntityDetails.vue";
import moment from "moment";
import { mapActions } from "vuex";
import { createHelpers } from "vuex-map-fields";
import AccidentService from "@/services/AccidentService";
import WidgetService from "../services/WidgetService";

const { mapFields } = createHelpers({
  getterType: "accident/getField",
  mutationType: "accident/updateField",
});

export default {
  components: {
    Entity,
  },
  props: ["id", "preview", "readOnly"],
  computed: {
    accidentId() {
      return this.$route.params.id || this.id;
    },
    widgets() {
      return [
        { ...WidgetService.AccidentPdfFile, namespace: "accident" },
        {
          ...WidgetService.AccidentVehicle,
          namespace: "accident",
          field: "MyVehicle",
        },
        {
          ...WidgetService.AccidentVehicle,
          namespace: "accident",
          field: "OtherVehicle",
          title: "הרכב האחר",
        },
      ];
    },
    documentStatus() {
      switch (this.PdfStatus) {
        case 0:
          return "-";
        case 1:
          return "הושלם";
        case 2:
          return "ממתין";
        default:
          return "-";
      }
    },
    innerComponent() {
      return AccidentEntityDetails;
    },
    ...mapFields(["CreateDate", "PlateNumber", "PdfStatus"]),
  },
  filters: {
    date(value) {
      const date = moment(value, "YYYY-MM-DDThh:mm");
      return date.format("DD/MM/YYYY hh:mm");
    },
  },
  data() {
    return {
      entityReady: false,
    };
  },
  async created() {
    this.init();
  },
  methods: {
    async init(callback) {
      try {
        await this.load({
          id: this.accidentId,
        });
        this.entityReady = true;
        if (callback) {
          callback();
        }
      } catch (err) {
        console.log(err);
      }
    },
    onFormSave({ refresh, closeDialog, model, saving }) {
      saving(true);
      AccidentService.update(this.accidentId, {
        ...this.$store.state.report,
        ...model,
      })
        .then(() => {
          this.init(refresh);
          this.$buefy.toast.open({
            type: "is-success",
            message: "נשמר בהצלחה!",
            duration: 4000,
          });
        })
        .catch(() => {
          this.$buefy.toast.open({
            type: "is-danger",
            message: "קרתה תקלה, השמירה לא התבצעה!",
            duration: 8000,
          });
          refresh();
        })
        .finally(() => {
          saving(false);
          closeDialog();
        });
    },
    onFormDiscard({ restoreState, closeDialog }) {
      restoreState();
      closeDialog();
    },
    ...mapActions({
      load: "accident/load",
    }),
  },
};
</script>

<style>
</style>
