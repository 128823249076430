<template>
  <div class="Accidents__View">
    <EntityListLayout :tabs="tabs">
        <template #title>תאונות</template>
        <template v-slot:counter="slotProps">
          סה"כ <b>{{getTotalRecords(slotProps)}} </b>
        </template>
    </EntityListLayout>
  </div>
</template>
<script>
import AccidentService from '@/services/AccidentService';
import EntityListLayout from '@/components/EntityListLayout.vue';
import FieldService from '@/services/FieldService';
import TableActions from '@/services/TableActionsService';
import AccidentEntity from '@/views/AccidentEntity.vue';

export default {
  name: 'Accidents',
  data() {
    return {
     tabs: [],
    };
  },
  components: {
    EntityListLayout,
  },
  created() {
    const accidentsFields = FieldService.list('accidents');
    const sideViewEntity = {
        component: AccidentEntity,
        preview: true,
        readOnly: true,
        title: 'פרטי תאונה',
        recordUrl: 'accidents',
    };

    const fields = [
      { ...accidentsFields.CreateDate, checked: true },
      { ...accidentsFields.VehiclePlateNumber, checked: true },
      { ...accidentsFields.AccidentType, checked: true },
      { ...accidentsFields.AccidentDate, checked: true },
      { ...accidentsFields.Description, checked: true },
      { ...accidentsFields.FieldPdfStatus, checked: true },
      { ...accidentsFields.Document, checked: true },
    ];

    this.tabs = [{
        id: 'AccidentsList',
        fields,
        title: 'כל התאונות',
        getDataAsync: this.getAllAccidents,
        perPage: 20,
        checkableRows: true,
        sideViewEntity,
        tableActions: [
          TableActions.requestDriverSignature,
        ],
    }];
  },
  methods: {
    getAllAccidents({ cancelToken, ...rest }) {
      return AccidentService.all(rest, cancelToken.token)
        .then((r) => ({ data: { Items: r.data, TotalResults: r.data?.length } }));
    },
    getTotalRecords(slotProps) {
      return slotProps.tab && slotProps.tab.total && slotProps.tab.total;
    },
  },
};
</script>
